import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkGrid from '../../components/terms/link-grid'
import PolicyDetails from '../../components/terms/professional-services-agreement-previous'

const ProfessionalServicesAgreement = () => {
  return (
    <Layout>
      <SEO
        title='Sitey Professional Service Agreement'
        pageType='terms'
        flowType='none'
      />
      {typeof window !== 'undefined' && (
        <LinkGrid pathname={location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default ProfessionalServicesAgreement

import React from 'react'
import Policy from './professional-services-agreement-previous.styles'

const PolicyDetails = () => {
  return (
    <Policy>
      <Policy.title>Sitey.com Professional Services Agreement</Policy.title>
      <Policy.paragraph>
        This PROFESSIONAL SERVICES AGREEMENT (the "Agreement") is an agreement
        between Sitey ("Company") and you ("Customer" or "you") and applies to
        all professional services purchased by you (collectively, the
        "Services") in connection with your website (the "Website").
      </Policy.paragraph>
      <Policy.boldParagraph>
        PLEASE READ THIS AGREEMENT CAREFULLY. BY PURCHASING THE SERVICES YOU
        AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT AND ALL TERMS AND
        CONDITIONS INCORPORATED BY REFERENCE IN THIS AGREEMENT, INCLUDING
        WITHOUT LIMITATION, THE PRIVACY NOTICE, USER AGREEMENT AND OTHER
        POLICIES LOCATED AT:
        <Policy.inlineLink to='../../terms/terms-of-service'>
          https://sitey.com/terms/terms-of-service
        </Policy.inlineLink>
      </Policy.boldParagraph>
      <Policy.paragraph sm>
        Company may in its sole discretion change or modify this Agreement at
        any time. We will post a notice of any significant changes to this
        Agreement on the Company website for at least thirty (30) days after the
        changes are posted and will indicate at the bottom of this Agreement the
        date these terms were last revised. Any changes or modifications to this
        Agreement shall be effective and binding on you as of the date indicated
        in a notice posted on this page, together with any options you may have
        to accept or reject such changes where required by law or as otherwise
        made available. If no effective date for the changes is specified, your
        use of the Services after such changes or modifications shall constitute
        your acceptance of the Agreement as modified. If you do not agree to
        abide by this Agreement, you are not authorized to use or access the
        Services and your sole remedy is to cancel your Services.
      </Policy.paragraph>
      <Policy.list isCircle>
        <Policy.listChild mainTitle>
          TERM AND TERMINATION
          <Policy.list>
            <Policy.listChild>
              <Policy.listTitle>Term. </Policy.listTitle>
              <Policy.listParagraph>
                The initial term of the Services purchased by you will be for
                the time period set forth in the confirmation email sent to you
                when you order the Services (the "Initial Term"). Unless you
                cancel prior to the end of the Initial Term, the Services will
                automatically renew on a monthly basis (each a "Renewal
                Period"). You acknowledge, agree, and authorize us to
                automatically bill the applicable Fees (as defined in Section 8)
                and/or charge your credit card or other payment method on file
                up to fourteen (14) days prior to the end of each Renewal
                Period, unless you terminate or cancel the Services prior to
                such charge. In the event that the Services you purchase include
                a minimum term as set forth in your confirmation email (the
                "Minimum Term"), upon completion of the Minimum Term, this
                agreement will automatically renew for subsequent Renewal
                Periods. The "Term" of this Agreement shall include the Initial
                Term or any applicable Minimum Term and all Renewal Periods, if
                any. Company cannot guarantee that the Services will be
                completed by a specific date but will use commercially
                reasonable efforts to perform the Services in an efficient and
                timely manner.
              </Policy.listParagraph>
            </Policy.listChild>
            <Policy.listChild>
              <Policy.listTitle>Termination. </Policy.listTitle>
              <Policy.listParagraph>
                Subject to Section 8(b) (if applicable), you may terminate or
                cancel the Services you purchased at any time during the Term by
                giving Company notice by phone. The cancellation request is
                subject to verification of account ownership, as determined in
                Company’s sole discretion. You are obligated to pay all Fees and
                charges accrued prior to the effectiveness of any cancellation.
                This Agreement may be terminated by Company: (i) immediately if
                Customer fails to pay any Fees due hereunder; (ii) if Customer
                fails to cooperate with Company or hinders Company's ability to
                perform the Services; or (iii) if Customer breaches the terms of
                this Agreement. Further, a termination of Customer’s underlying
                hosting account will result in the termination of this
                Agreement.
                <Policy.listParagraphBold>
                  If Customer terminates this Agreement prior to the completion
                  of any applicable Minimum Term, Company may charge Customer an
                  early termination fee as described in
                  <Policy.inlineLink to='./#FEES'>
                    Section 8 below.
                  </Policy.inlineLink>
                </Policy.listParagraphBold>
              </Policy.listParagraph>
            </Policy.listChild>
          </Policy.list>
        </Policy.listChild>
        <Policy.listChild mainTitle>SCOPE OF SERVICES</Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listTitle>Scope of Work. </Policy.listTitle>
            <Policy.listParagraph>
              Company agrees to provide the Services in accordance with the
              Services descriptions available on the Company’s website and the
              scope outlined in the welcome email sent to you. For Services that
              include website design and marketing services, the scope of
              Services is further detailed in the confirmation email following
              your initial consultation with Company.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Changes to Order. </Policy.listTitle>
            <Policy.listParagraph>
              Any changes to the scope of website design or marketing services
              must be documented in writing and submitted through Company’s
              ticketing system (the "Change Order"). In the event of a conflict
              between the terms of this Agreement and a Change Order, the terms
              of this Agreement shall govern.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle>CUSTOMER RESPONSIBILITIES</Policy.listChild>
        <Policy.singleList>
          You agree to perform all tasks required and to provide all necessary
          assistance and cooperation to Company in order to complete the
          Services in a timely manner. It is solely your responsibility to
          provide any equipment or software that may be necessary for your use
          of the Services and to ensure that such equipment and software are
          compatible with the Services. To the extent that the performance of
          any of Company’s obligations under this Agreement may depend upon your
          performance of your obligations, Company is not responsible for any
          delays due to your failure to perform your obligations in a timely
          manner.
        </Policy.singleList>
        <Policy.listChild mainTitle>LICENSE GRANT</Policy.listChild>
        <Policy.singleList>
          Solely for purposes of providing the Services, you hereby grant to
          Company a non-exclusive, royalty-free, worldwide right and license to:
          (i) use, reproduce, publicly perform, publicly display, modify,
          translate, excerpt (in whole or in part), publish and distribute,
          photographs, illustrations, graphics, audio clips, video clips, text,
          data or any other information, content, display, intellectual
          property, or material (whether written, graphic, sound, or otherwise)
          you provide, including without limitation, your logos and trademarks
          (collectively, "Customer Content"); and (ii) make archival or back-up
          copies of the Customer Content and the Website. Except for the rights
          expressly granted above, Company is not acquiring any right, title or
          interest in or to the Customer Content, all of which shall remain
          solely with you.
        </Policy.singleList>
        <Policy.listChild mainTitle>WEBSITE DESIGN</Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listTitle>Design. </Policy.listTitle>
            <Policy.listParagraph>
              In the event the Services include website design services, the
              design of the Website shall be in substantial conformity with
              reasonable specifications provided by you to Company and within
              the scope of work provided by Company to you in the welcome email.
              You will deliver your Website specifications to the design agent
              during the initial phone consultation (approximate duration of 45
              minutes) and/or through the email ticketing system. Upon Company’s
              completion of the design, you will have the option to either (i)
              review the Website with a Company representative during an
              additional phone consultation (approximate duration of 30-45
              minutes), or (ii) a thirty (30) minute video walkthrough of the
              Website. The Website review is to provide you with the basics of
              the backend of the Website so that you can take control of
              maintaining the Website. As stated below, once the Website design
              is completed, you are solely responsible for the maintenance of
              the Website including without limitation, backing up the Website
              and identifying and/or resolving any security or malware issues.
              Once the Website design is complete, Company is not responsible
              for maintaining the Website.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Revision Process. </Policy.listTitle>
            <Policy.listParagraph>
              You may submit the Website for up to two (2) rounds of revisions.
              You are encouraged to provide as much instruction and direction as
              possible with each submission for revisions. Each round of
              revisions will include a thirty (30) minute call with your
              designer.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>
              Accessibility of Website During Construction.{' '}
            </Policy.listTitle>
            <Policy.listParagraph>
              Throughout the construction of the prototype and the final
              Website, the Website will be accessible to you through your
              hosting account file manager. Please note that you should not make
              any changes to the prototype during the construction phase unless
              instructed to do so by us. Altering files during Website
              construction may cause delays in the completion of the Website.
              Until you have approved the final Website, none of the web pages
              for the Website will be publicly accessible.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Content Delivery. </Policy.listTitle>
            <Policy.listParagraph>
              You must provide Company with the complete Customer Content for
              all web pages of the Website within thirty (30) days of the date
              of your initial purchase of the Services. If you do not submit
              complete Customer Content by such deadline, Company may cancel the
              project and you are not entitled to any refund of any Fees paid.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Copyright to Website. </Policy.listTitle>
            <Policy.listParagraph>
              You acknowledge, understand and agree that Company may use its own
              and/or may purchase third party licenses for products or services
              that are necessary for Company to design and develop the Website.
              Such products may include, but are not limited to, server-side
              applications, clip art, "back-end" applications, music, stock
              images, or other copyrighted work (collectively, "Third Party
              Assets") which are required for Company to design and develop the
              Website. You further acknowledge and understand that any Third
              Party Assets used to design and develop the Website are owned by
              Company and/or its licensors and cannot be transferred to you, and
              are hereby expressly not transferred to you. As between Company
              and you, all Third Party Assets shall remain the property of
              Company and/or its licensors. You are prohibited from using any
              Third Party Assets on a stand-alone basis separate from the
              Website and from removing any metadata from any Third Party
              Assets. Third Party Assets that are owned or purchased by Company
              may also be used in the design and development of websites for
              other Company customers. Subject to the limitations set forth in
              this Section 5, upon payment of the Fees due to Company under this
              Agreement, you shall retain a worldwide right, title, and interest
              in and to the Website. Company and its licensors expressly retain
              the right to display graphics and other web design elements of the
              Website as examples of their work in their respective portfolios.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Prohibited Content. </Policy.listTitle>
            <Policy.listParagraph>
              Company will not knowingly include any of the following in the
              Website or in Customer's directory on Company's web servers: (i)
              text, graphics, sound, or animations that might be viewed as
              obscene or illegal; (ii) links to other websites that might be
              viewed as obscene or related in any way to illegal activities; or
              (iii) destructive elements or destructive programming of any type,
              all as determined by Company in its sole discretion.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Maintenance and Repair. </Policy.listTitle>
            <Policy.listParagraph>
              The Services do not include maintenance of the Website. Customer
              is solely responsible for maintaining the Website once it is
              completed. The completion date of the Website is stated in the
              Customer’s account. Any changes to the Website requested by
              Customer outside of the scope of the Services as set forth in this
              Agreement, the initial consultation, or any Change Order, will be
              billed at the hourly rates provided by Company.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle>MARKETING SERVICES</Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listTitle>Services. </Policy.listTitle>
            <Policy.listParagraph>
              Depending on the Services you purchase, Company may provide one or
              more of the marketing services described below.
            </Policy.listParagraph>
            <Policy.list isSquare>
              <Policy.listChild>
                <Policy.listTitle>
                  Managed Local Lift Services.{' '}
                </Policy.listTitle>
                <Policy.listParagraph>
                  Company may provide managed Local Lift services to help you
                  manage your business listings across various search engines
                  and social media platforms ("Managed Local Lift") pursuant to
                  the terms of your purchase. In connection with Company’s
                  provision of the Managed Local Lift services, you are required
                  to maintain a Local Lift account during the Term and you
                  authorize Company to update directory listings for business
                  name, address, phone number, hours of operation and other
                  business-specific information required for the Managed Local
                  Lift services. Once a directory listing is updated with the
                  information from your Managed Local Lift profile, you
                  authorize Company to take commercially reasonable measures to
                  prevent the information in your listings from being
                  overwritten by a third party during the Term. Please note that
                  upon the termination or expiration of the Term, Company will
                  no longer have access to update your business information and
                  your directory information may be overwritten at any time.
                </Policy.listParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.listTitle>SEO Services. </Policy.listTitle>
                <Policy.listParagraph>
                  In connection with Company’s provision of search engine
                  optimization services ("SEO Services"), you authorize Company
                  to build backlinks through article writing and social
                  bookmarking to influence the ranking of the Website on certain
                  search engines. SEO Services are intended to obtain
                  preferential positioning for the Website in selected search
                  engines.
                </Policy.listParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.listTitle>Pay Per Click Services. </Policy.listTitle>
                <Policy.listParagraph>
                  In connection with Company’s provision of pay per click
                  services ("PPC Services"), you authorize Company to use
                  relevant keywords and/or phrases for positioning the contents
                  of the Website in Google’s ad network. PPC Services are
                  intended to obtain preferential positioning for the Website on
                  search engines and/or social media platforms.
                </Policy.listParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.listTitle>Social Lift Services. </Policy.listTitle>
                <Policy.listParagraph>
                  In connection with Company’s provision of Social Lift
                  services, you authorize Company to use relevant Customer
                  Content and images for branding, social engagement, and/or
                  driving traffic to the Website in Facebook and Twitter. Social
                  Lift services are intended to create/build brand awareness,
                  engage followers, and bring traffic to the Website via social
                  networks.
                </Policy.listParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.listTitle>Reporting. </Policy.listTitle>
                <Policy.listParagraph>
                  Company will report results for SEO, PPC, and Social Lift
                  Services on a regular basis.
                </Policy.listParagraph>
              </Policy.listChild>
            </Policy.list>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Customer Acknowledgements. </Policy.listTitle>
            <Policy.listParagraph>
              You understand, acknowledge and agree that:
            </Policy.listParagraph>
            <Policy.list isSquare>
              <Policy.listChild>
                <Policy.listParagraph>
                  Company has no control over the policies of search engines or
                  directories with respect to the type of websites and/or
                  content that they accept now or in the future. The Website may
                  be excluded from any search engine or directory at any time at
                  the sole discretion of the search engine or directory entity.
                  Company will resubmit those web pages that have been dropped
                  from the index but cannot guarantee that they will be accepted
                  by the search engine.
                </Policy.listParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.listParagraph>
                  Some search engines and directories may take two (2) to four
                  (4) months or longer after submission to list the Website.
                  Certain search engines and directories may stop accepting
                  submissions for an indefinite period of time. Certain search
                  engines and directories may drop listings for no apparent or
                  predictable reason. Often a listing will "reappear" without
                  any additional submissions. Should the listing not reappear,
                  Company will re-submit the Website based on the current
                  policies of the search engine or directory. Certain search
                  engines and directories may offer expedited listing services
                  for a fee. You are responsible for all expedited service fees
                  unless otherwise expressly stated and such fees will only be
                  incurred with your prior approval.
                </Policy.listParagraph>
              </Policy.listChild>
              <Policy.listChild>
                <Policy.listParagraph>
                  In the event that you have purchased both website design and
                  marketing services from Company, the marketing services will
                  not commence until the website design portion of the Services
                  is completed with the Website launch.
                </Policy.listParagraph>
              </Policy.listChild>
            </Policy.list>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Website Changes. </Policy.listTitle>
            <Policy.listParagraph>
              Company is not responsible for any Website changes not made by
              Company that adversely affect the search engine or directory
              rankings of the Website.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Additional Marketing Services. </Policy.listTitle>
            <Policy.listParagraph>
              Additional marketing services may be provided by Company for an
              additional cost, including for example, re-constructing meta-tags,
              keywords, and content.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle>WP LIVE</Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listTitle>Services. </Policy.listTitle>
            <Policy.listParagraph>
              WP Live supports, educates, and advises you on how to create a
              successful WordPress website. Depending on the subscription plan
              that you choose, the WP Live services may include expedited
              responses to your support tickets, live chat support, and live
              telephone support.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Changing Plans. </Policy.listTitle>
            <Policy.listParagraph>
              In the event your Services include WP Live services and you wish
              to change your WP Live services plan during the Term, the Fees
              already paid for the then current Term will be prorated and the
              remaining amount will be applied to the Fees due for the new plan.
              The renewal date of such Services will not change.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle id={'FEES'}>
          FEES
        </Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listTitle>Fees. </Policy.listTitle>
            <Policy.listParagraph>
              The fees for the Services shall be presented to you at the time of
              your purchase and also set forth in the confirmation email (the
              "Fees").
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Early Termination Fee. </Policy.listTitle>
            <Policy.listParagraph>
              If you terminate this Agreement prior to the completion of any
              applicable Minimum Term, Company may charge you an early
              termination fee in an amount equal to the Fees due for the number
              of whole months remaining in the Minimum Term at the time of
              termination, not to exceed three hundred dollars ($300) (the
              "Early Termination Fee"). In the event that the Services you
              purchase include website design services and you would like to
              take the newly designed website with you, in addition to the Early
              Termination Fee you will be charged the difference between the
              total Fees you have paid to date for the Services and two thousand
              dollars ($2,000).
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Project Abandonment. </Policy.listTitle>
            <Policy.listParagraph>
              If after repeated attempts to begin, continue, or finalize the
              website design or marketing Services, you fail to participate, or
              become otherwise unresponsive to Company’s requests for a period
              of two (2) months or longer, the project may be considered
              abandoned and you shall not be eligible for a refund of any kind.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Hosting Payments. </Policy.listTitle>
            <Policy.listParagraph>
              You must maintain the hosting account(s) connected with the
              Website in good standing. Any delays by Company in delivering the
              Services will not be a reason to delay payment for hosting
              services. If your hosting account becomes past-due, Company may
              delay or suspend the Services until the account is in good
              standing. Failure to pay for hosting services may also result in
              cancellation of the Services without a refund.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Automatic Renewals. </Policy.listTitle>
            <Policy.listParagraph>
              By purchasing the Services, you agree to allow Company to place
              your account on a recurring payment plan. The account will
              automatically be re-billed according to the term length of the
              Services you select in your initial purchase. The Fees will
              automatically be re-billed up to fourteen (14) days prior to the
              payment date for each Renewal Term unless you cancel. You may
              cancel the Services you purchased at any time during the Term by
              giving Company notice by phone.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Refunds. </Policy.listTitle>
            <Policy.listParagraph>
              Unless otherwise specifically stated in this Agreement, the Fees
              for the Services are nonrefundable. In the event that Company
              terminates this Agreement, you shall receive a prorated refund of
              any prepaid Fees for the then current Term, provided that such
              termination is not a result of your breach of this Agreement or
              Company’s terms of service.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle>
          REPRESENTATIONS AND WARRANTIES
        </Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listTitle>
              Company Representations and Warranties.{' '}
            </Policy.listTitle>
            <Policy.listParagraph>
              Company represents and warrants that the Services will be provided
              consistent in all material respects with the applicable Services
              descriptions available on the Company’s website. Your sole and
              exclusive remedy, and Company’s sole obligation, for breach of the
              foregoing warranty shall be for Company, at its option, to
              re-perform the defective Services at no additional cost to you.
              The foregoing warranties shall not apply to performance issues or
              defects in the Services (a) caused by factors outside of Company’s
              reasonable control; (b) that resulted from any actions or
              inactions of Customer or any third parties; or (c) that resulted
              from Customer’s equipment or any third-party equipment not within
              Company’s sole control.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>Disclaimer. </Policy.listTitle>
            <Policy.listParagraph>
              THE SERVICES PROVIDED UNDER THIS AGREEMENT ARE PROVIDED ON AN "AS
              IS" AND "AS AVAILABLE BASIS." EXCEPT AS EXPRESSLY PROVIDED IN THIS
              SECTION, COMPANY AND ITS AFFILIATES, EMPLOYEES, AGENTS, SUPPLIERS
              AND LICENSORS DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING BUT
              NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS
              FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, FOR THE SERVICES
              PROVIDED HEREUNDER. COMPANY AND ITS AFFILIATES, EMPLOYEES, AGENTS,
              SUPPLIERS AND LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES (I)
              THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR FREE OR COMPLETELY
              SECURE; (II) AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE
              OF THE SERVICES; OR (III) AS TO THE ACCURACY, RELIABILITY OR
              CONTENT OF ANY INFORMATION PROVIDED THROUGH THE SERVICES. COMPANY
              AND ITS AFFILIATES, EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS ARE
              NOT LIABLE, AND EXPRESSLY DISCLAIM ANY LIABILITY, FOR THE CONTENT
              OF ANY DATA TRANSFERRED EITHER TO OR FROM USERS OR STORED BY USERS
              ON OR THROUGH THE SERVICES. THE TERMS OF THIS SECTION SHALL
              SURVIVE ANY TERMINATION OF THIS AGREEMENT.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listTitle>
              Customer Representations and Warranties.{' '}
            </Policy.listTitle>
            <Policy.listParagraph>
              You represent and warrant that any Customer Content that you
              provide to Company for inclusion in the Website or use of the
              Services is owned by you, or you have permission from the rightful
              owner to use such intellectual property, and you will hold
              harmless, protect, and defend Company and its subcontractors from
              any claim or suit arising from Company’s use of the Customer
              Content as set forth this Agreement.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle>COMPLIANCE WITH LAWS</Policy.listChild>
        <Policy.singleList>
          You agree that you are solely responsible for complying with all
          applicable laws, taxes, and tariffs in connection with your use of the
          Services and the Website, including without limitation those affecting
          Internet electronic commerce, and will hold harmless, protect, and
          defend Company and its subcontractors from any claim, suit, penalty,
          tax, or tariff arising from your use of the Services or the Website.
        </Policy.singleList>
        <Policy.listChild mainTitle>LIMITATION OF LIABILITY</Policy.listChild>
        <Policy.list>
          <Policy.listChild>
            <Policy.listParagraph>
              IN NO EVENT WILL COMPANY OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL,
              EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR
              ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE
              SERVICES, INCLUDING FROM ANY INTERRUPTION OF SERVICES, EVEN IF
              COMPANY IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES.
            </Policy.listParagraph>
          </Policy.listChild>
          <Policy.listChild>
            <Policy.listParagraph>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
              COMPANY'S LIABILITY TO YOU OR ANY PARTY CLAIMING THROUGH YOU FOR
              ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, IS
              LIMITED TO THE FEES YOU PAID TO COMPANY FOR THE SERVICES PROVIDED
              UNDER THIS AGREEMENT. THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF
              MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
            </Policy.listParagraph>
          </Policy.listChild>
        </Policy.list>
        <Policy.listChild mainTitle>FORCE MAJEURE</Policy.listChild>
        <Policy.singleList>
          Neither party is liable for any default or delay in the performance of
          any of its obligations under this Agreement (other than failure to
          make payments when due) if such default or delay is caused, directly
          or indirectly, by forces beyond such party's reasonable control,
          including, without limitation, fire, flood, acts of God, labor
          disputes, accidents, acts of war or terrorism, interruptions of
          transportation or communications, supply shortages or the failure of
          any third party to perform any commitment relative to the production
          or delivery of any equipment or material required for such party to
          perform its obligations hereunder.
        </Policy.singleList>
        <Policy.listChild mainTitle>RELATIONSHIP OF PARTIES</Policy.listChild>
        <Policy.singleList>
          Company and Customer are independent contractors and nothing contained
          in this Agreement places Company and Customer in the relationship of
          principal and agent, partners or joint venturers. Neither party has,
          expressly or by implication, or may represent itself as having, any
          authority to make contracts or enter into any agreements in the name
          of the other party, or to obligate or bind the other party in any
          manner whatsoever.
        </Policy.singleList>
        <Policy.listChild mainTitle>ASSIGNMENT</Policy.listChild>
        <Policy.singleList>
          You may not assign or transfer this Agreement or any of its rights or
          obligations hereunder, without the prior written consent of Company.
          Any attempted assignment in violation of the foregoing provision shall
          be null and void and of no force or effect whatsoever. Company may
          assign this Agreement and Company’s rights and obligations hereunder,
          and Company may engage subcontractors or agents in performing its
          duties and exercising its rights hereunder, without your consent. This
          Agreement shall be binding upon and shall inure to the benefit of the
          parties hereto and their respective successors and permitted assigns.
        </Policy.singleList>
        <Policy.listChild mainTitle>WAIVER</Policy.listChild>
        <Policy.singleList>
          No failure or delay by any party hereto to exercise any right or
          remedy hereunder shall operate as a waiver thereof, nor shall any
          single or partial exercise of any right or remedy by any party
          preclude any other or further exercise thereof or the exercise of any
          other right or remedy. No express waiver or assent by any party hereto
          to any breach of or default in any term or condition of this Agreement
          shall constitute a waiver of or an assent to any succeeding breach of
          or default in the same or any other term or condition of this
          Agreement.
        </Policy.singleList>
        <Policy.listChild mainTitle>MODIFICATION OF SERVICES</Policy.listChild>
        <Policy.singleList>
          Company reserves the right to modify, change, or discontinue any
          aspect of the Services at any time, provided that you will be notified
          in advance of any material change and given the opportunity to cancel
          without penalty in the event you do not agree to such change.
        </Policy.singleList>
        <Policy.listChild mainTitle>SEVERABILITY</Policy.listChild>
        <Policy.singleList>
          If any term, clause or provision hereof is held invalid or
          unenforceable by a court of competent jurisdiction, such invalidity
          shall not affect the validity or operation of any other term, clause
          or provision and such invalid term, clause or provision shall be
          deemed to be severed from the Agreement.
        </Policy.singleList>
        <Policy.listChild mainTitle>ENTIRE AGREEMENT</Policy.listChild>
        <Policy.singleList>
          This Agreement, including documents incorporated herein by reference,
          constitutes the entire understanding of the parties in connection with
          the Services, and revokes and supersedes all prior agreements between
          the parties with respect to the matters covered hereby.
        </Policy.singleList>
      </Policy.list>
      <Policy.updatedText>Last modified on August 03, 2020</Policy.updatedText>
    </Policy>
  )
}

export default PolicyDetails

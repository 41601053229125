import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const Policy = styled.div`
  margin: ${theme.space[10]}px auto;
  padding: ${theme.space[5]}px;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
  color: #686868;
  display: flex;
  flex-direction: column;
`
Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[4]}px 0;
  color: #000000;
`

Policy.boldParagraph = styled.span`
  font-size: ${theme.font['sm']};
  padding: ${theme.space[2]}px 0;
  font-style: bold;
  font-weight: 600;
`

Policy.paragraph = styled.span`
  font-size: ${(props) => (props.sm ? theme.font['sm'] : theme.font['md'])};
  padding: ${theme.space[5]}px 0;
`

Policy.inlineLink = styled(Link)`
  color: ${theme.colors.font.green};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
  :before {
    content: ' ';
  }
  :after {
    content: ' ';
  }
`

const determineListStyle = (props) => {
  if (props.isCircle) {
    return 'disc'
  } else if (props.isSquare) {
    return 'square'
  } else {
    return 'upper-alpha'
  }
}

Policy.list = styled.ol`
  list-style-type: ${(props) => determineListStyle(props)};
  padding-left: ${theme.space[8]}px;
`

Policy.singleList = styled.ol`
  padding-left: ${theme.space[8]}px;
  font-size: ${theme.font['xl']};
  line-height: ${theme.space[8]}px;
`

Policy.listChild = styled.li`
  padding: ${theme.space[6]}px 0;
  font-size: ${(props) =>
    props.mainTitle ? theme.font['xl'] : theme.font['base']};
  font-weight: ${(props) => (props.mainTitle ? 600 : 400)};
  text-transform: ${(props) => (props.mainTitle ? 'uppercase' : 'none')};
`

Policy.listTitle = styled.span`
  font-style: italic;
  font-size: ${theme.font['xl']};
`

Policy.listParagraph = styled.span`
  font-size: ${theme.font['xl']};
  line-height: ${theme.space[8]}px;
`

Policy.listParagraphBold = styled.span`
  font-weight: 600;
`

Policy.updatedText = styled.span`
  margin-top: ${theme.space[8]}px;
  padding-left: ${theme.space[4]}px;
  font-size: ${theme.font['sm']};
`

export default Policy
